import { getValuesFromFileMetadata } from "@/entities/submission";
import {
  Thread,
  convertToThreadData,
  threadCollectionKey,
  ComplementedThread,
  ComplementedThreadData,
  ThreadPost
} from "@/entities/thread";
import { ThreadMaster, convertToThreadMaster } from "@/entities/thread_master";
import { getDocIdsOfStudentRef } from "@/entities/student";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import mime from "mime";

export async function getThreadsOfStudent(
  studentRef: firebase.firestore.DocumentReference,
  options?: {
    threadId?: string;
    limit?: number;
    offsetRef?: firebase.firestore.DocumentReference;
    submittableBefore?: number;
  }
): Promise<Thread[] | null> {
  try {
    const { threadId, limit, offsetRef, submittableBefore } = {
      limit: 20,
      ...(options || {})
    };
    if (!threadId) {
      // 提出物リストを取得
      let query = studentRef
        .collection(threadCollectionKey)
        .orderBy("submittableFrom", "desc");
      if (limit > 0) {
        query = query.limit(limit);
      }
      if (submittableBefore && submittableBefore > 0) {
        query = query.where("submittableFrom", "<", submittableBefore);
      }
      if (offsetRef) {
        const offsetSnapshot = await offsetRef.get();
        query = query.startAfter(offsetSnapshot);
      }
      const submissionsSnapshot = await query.get();
      if (submissionsSnapshot.empty) {
        return [];
      }

      return submissionsSnapshot.docs
        .map(doc => ({
          data: convertToThreadData(doc.data()),
          ref: doc.ref
        }))
        .sort((a, b) => a.data.displayEndAt - b.data.displayEndAt);
    } else {
      // に対応するドキュメントを取得
      const snapshot = await studentRef
        .collection(threadCollectionKey)
        .doc(threadId)
        .get();
      const data = snapshot.data();
      if (!snapshot.exists || !data) {
        return null;
      }
      return [
        {
          ref: snapshot.ref,
          data: convertToThreadData(data)
        }
      ];
    }
  } catch (e) {
    return null;
  }
}

export async function putThread(
  submissionRef: firebase.firestore.DocumentReference,
  options: {
    posts: ThreadPost[];
  }
): Promise<void> {
  await submissionRef.update(options);
}

export async function complementThreads(
  threads: Thread[]
): Promise<ComplementedThread[]> {
  return new Promise((resolve, reject) => {
    const threadMastersToGet = threads
      .map(submission => submission.data.threadMasterRef)
      .filter((ref, index, self) => {
        return self.findIndex(m => m.id === ref.id) === index;
      });

    // 画像以外のファイルのメタデータを取得する
    const fileMetadataToGet = threads.map(s => {
      return Promise.all(
        s.data.posts.map(
          async ({ type, url }: { type: string; url: string }) => {
            if (type === "text") return { size: 0, contentType: "" };
            const ref = (await firebase.storage()).refFromURL(url);
            return ref.getMetadata();
          }
        )
      );
    });

    Promise.all([
      Promise.all(threadMastersToGet.map(ref => ref.get())),
      // 画像以外のファイルのメタデータを取得する
      Promise.all(fileMetadataToGet),
      threads
    ])
      .then(results => {
        const threadMasters = results[0];
        const fileMetaData = results[1];
        const dataMaps = results[2].map((_thread, threadIndex) => {
          const matchedThreadMaster = threadMasters.find(
            m => m.ref.id === _thread.data.threadMasterRef.id
          );
          const threadData = matchedThreadMaster?.data();
          let threadMaster: ThreadMaster | undefined;
          if (threadData && matchedThreadMaster) {
            threadMaster = convertToThreadMaster(
              threadData,
              matchedThreadMaster.ref
            );
          }

          const ComplementedThreadData = {
            ..._thread.data,
            posts: fileMetaData[threadIndex].map((metadata, nodeIndex) => {
              const values = getValuesFromFileMetadata(
                metadata.size,
                metadata.contentType
              );
              return {
                ..._thread.data.posts[nodeIndex],
                ...values,
                contentTypeToDisplay:
                  values.contentIconType !== "file"
                    ? values.contentTypeToDisplay
                    : `${mime.getExtension(metadata.contentType)}ファイル` ??
                      values.contentTypeToDisplay
              };
            })
          };

          const result = {
            ..._thread,
            ref: _thread.ref,
            data: ComplementedThreadData as ComplementedThreadData,
            threadMasterRef: threadMaster?.ref,
            threadMasterData: threadMaster?.data
          } as ComplementedThread;
          return result;
        });
        resolve(dataMaps);
      })
      .catch(reject);
  });
}

export async function createNotificationForTutors(
  studentRef: firebase.firestore.DocumentReference,
  data: {
    title: string;
    threadDocId: string;
    content: string;
  }
): Promise<void> {
  const [schoolDocId, classroomDocId, studentDocId] =
    getDocIdsOfStudentRef(studentRef);
  const req = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("create_notification_for_tutors");
  const res = await req({
    schoolDocId,
    classroomDocId,
    studentDocId,
    threadDocId: data.threadDocId,
    title: data.title,
    content: data.content,
    type: "post_submission"
  });
  if (res.data.error) {
    throw new Error(res.data.error);
  }
  return;
}

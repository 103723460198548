import {
  convertToMessage,
  Message,
  messageCollectionKey,
  MessageUser,
  MessageContent
} from "@/entities/message";
import { getDocIdsOfStudentRef } from "@/entities/student";
import store from "@/store";
import firebase from "firebase/app";
import "firebase/firestore";

export function getMessageStream(
  studentRef: firebase.firestore.DocumentReference,
  limit = 0
): firebase.firestore.Query {
  let query = studentRef
    .collection(messageCollectionKey)
    .orderBy("timestamp", "desc");
  if (limit > 0) {
    query = query.limit(limit);
  }
  return query;
}

export async function getMessages(
  studentRef: firebase.firestore.DocumentReference,
  limit = 0,
  offsetRef?: firebase.firestore.DocumentReference
): Promise<Message[]> {
  let query = studentRef
    .collection(messageCollectionKey)
    .orderBy("timestamp", "desc");
  if (limit > 0) {
    query = query.limit(limit);
  }
  if (offsetRef) {
    const offsetSnapshot = await offsetRef.get();
    query = query.startAfter(offsetSnapshot);
  }

  const messagesSnapshot = await query.get();
  if (messagesSnapshot.empty) {
    return [];
  }

  const messages = messagesSnapshot.docs.filter(
    doc => doc.data().type !== "multiple"
  );
  const multipleMessages = messagesSnapshot.docs.filter(
    doc => doc.data().type === "multiple"
  );

  return [
    ...messages.map(m => {
      const data = m.data();
      return convertToMessage(data, m.ref);
    }),
    ...multipleMessages
      .map(m => {
        const data = m.data();
        return data.messageContents.map((c: MessageContent) => {
          return convertToMessage(
            {
              ...data,
              messageText: c.text,
              type: c.type
            },
            m.ref
          );
        });
      })
      .flat()
  ];
}

export async function sendTextMessage(
  studentRef: firebase.firestore.DocumentReference,
  from: MessageUser,
  messageText: string
): Promise<void> {
  const sendTextMessage = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("get_student_web_message");
  const { name } = from;
  const [schoolDocId, classroomDocId, studentDocId] = getDocIdsOfStudentRef(
    studentRef
  );
  await sendTextMessage({
    schoolDocId,
    classroomDocId,
    studentDocId,
    name,
    messageText
  });
}

export async function sendPostbackEvent(
  studentRef: firebase.firestore.DocumentReference,
  postback: string,
  params?: {
    newerTimerEnd?: string;
  }
): Promise<void> {
  const getPostbackEvent = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("get_postback_event");

  const docIds = getDocIdsOfStudentRef(studentRef);
  await getPostbackEvent({
    postback,
    school: docIds[0],
    classroom: docIds[1],
    params
  });
}

export async function sendImageMessage(
  studentRef: firebase.firestore.DocumentReference,
  from: MessageUser,
  imagePath: string
): Promise<void> {
  const sendTextMessage = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("get_student_web_message");
  const { name } = from;
  const [schoolDocId, classroomDocId, studentDocId] = getDocIdsOfStudentRef(
    studentRef
  );
  await sendTextMessage({
    schoolDocId,
    classroomDocId,
    studentDocId,
    name,
    messageText: imagePath,
    type: "image"
  });
}

export async function getUnreadMessagesLength(
  studentRef: firebase.firestore.DocumentReference<
    firebase.firestore.DocumentData
  >,
  execTime = 0
): Promise<number> {
  const unreadMessages = await studentRef
    .collection(messageCollectionKey)
    .where("checked", "==", false)
    .where("timestamp", ">", execTime)
    .orderBy("timestamp", "desc")
    .limit(100)
    .get();

  const filteredUnreadMessages = unreadMessages.docs.filter(
    doc => doc.data().from.type !== "student" && !doc.data().deleted
  );

  return filteredUnreadMessages.length;
}

export async function getAllMessagesRead(
  studentRef: firebase.firestore.DocumentReference<
    firebase.firestore.DocumentData
  >
) {
  const snapshot = await studentRef.collection(messageCollectionKey).get();
  if (!snapshot) return;
  await Promise.all([
    snapshot.docs.map(async doc => {
      if (
        !doc.exists ||
        doc.data().checked ||
        doc.data().from.type === "student"
      )
        return Promise.resolve();
      await doc.ref.update({ checked: true });
    })
  ]);
  await store.dispatch("getUnreadMessagesLength");
}

<template>
  <router-link
    :to="to"
    class="py-3 flex flex-col items-center"
    :class="dynamicWrapClass"
  >
    <div class="relative">
      <m-icon :type="icon" size="6" class="mb-1" />
      <span
        v-if="showUnreadCount && unreadMessagesLength > 0"
        class="unread absolute bg-red-400 rounded-full h-6 w-max flex items-center justify-center px-2 text-gray-500"
      >
        <span v-if="unreadMessagesLength >= 100">99+</span>
        <span v-else>{{ unreadMessagesLength }}</span>
      </span>
    </div>
    <p class="text-sm">{{ text }}</p>
  </router-link>
</template>

<script lang="ts">
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";
import store from "@/store";

@Options({
  components: {
    MIcon
  },
  props: {
    icon: String,
    text: String,
    to: String,
    select: Boolean
  }
})
export default class MNavItem extends Vue {
  icon!: string;
  text!: string;
  to!: string;
  select = false;
  messages = [];

  get showUnreadCount(): boolean {
    return this.icon === "message";
  }

  get unreadMessagesLength(): number {
    return store.state.unreadMessagesLength;
  }

  get dynamicWrapClass(): string {
    if (this.select) {
      return "text-primary-500";
    } else {
      return "text-gray-500";
    }
  }
}
</script>

<style scoped lang="scss">
.unread {
  top: -50%;
  right: -50%;
}
</style>

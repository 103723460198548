import { convertToStudent, Student, StudentData } from "@/entities/student";
import firebase from "firebase/app";
import "firebase/firestore";

export async function getStudent(
  studentRef: firebase.firestore.DocumentReference
): Promise<Student | null> {
  try {
    const snapshot = await studentRef.get();
    const data = snapshot.data();
    if (!snapshot.exists || !data) {
      return null;
    }

    return convertToStudent(data, studentRef);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function updateStudent(
  studentRef: firebase.firestore.DocumentReference,
  data: StudentData
): Promise<void> {
  await studentRef.update(data);
}

import { Group } from "@/entities/group";
import { LearningSubject } from "@/entities/learning";
import { RegistrantCameraStatus } from "@/entities/registrant";
import {
  convertToRoom,
  Room,
  roomCollectionKey,
  RoomData
} from "@/entities/room";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { getGroupOf } from "./group";

async function getGroupOfRoom(roomData: RoomData): Promise<Group | null> {
  if (roomData.group) {
    return await getGroupOf(roomData.group);
  } else {
    return null;
  }
}

export async function fetchRooms(
  studentId: string,
  schoolId: string,
  targetId: string,
  hideMingakuStudyRoom: boolean
): Promise<Room[]> {
  const db = firebase.firestore();

  const snapshot = await db
    .collection(roomCollectionKey)
    .orderBy("start", "desc")
    .get();
  if (snapshot.empty) {
    return [];
  }

  const rooms = snapshot.docs.map(doc => convertToRoom(doc.data(), doc.ref));
  const groups = await Promise.all(
    rooms.map(room => getGroupOfRoom(room.data))
  );

  return rooms.filter((room, i) => {
    // school による制限
    if (room.data.school && room.data.school.id !== schoolId) {
      return false;
    }

    // targetによる制限
    if (room.data.target && room.data.target.id !== targetId) {
      return false;
    }

    // 管理人が作成した学習室の領域と自身の領域が一致しているが、みんがく学習室を非表示にする設定がされている場合はfalseを返却
    if (
      room.data.target &&
      room.data.target.id === targetId &&
      hideMingakuStudyRoom
    ) {
      return false;
    }

    if (!room.data.group) {
      return true;
    }

    // groupによる制限
    const group = groups[i];
    if (group) {
      return group.data.result.map(item => item.id).includes(studentId);
    } else {
      return true;
    }
  });
}

export async function getRegistrant(
  studentRef: firebase.firestore.DocumentReference,
  roomId: string,
  goal: string,
  subjects: LearningSubject[],
  greeting: string,
  cameraStatus?: RegistrantCameraStatus,
  cameraComment?: string
): Promise<string> {
  const createZoomRegistrant = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("create_zoom_registrant");

  const student = studentRef.id;
  const classroomRef = studentRef.parent.parent;
  if (!classroomRef) {
    throw new Error("studentRef is not correct");
  }
  const classroom = classroomRef.id;
  const schoolRef = classroomRef.parent.parent;
  if (!schoolRef) {
    throw new Error("studentRef is not correct");
  }
  const school = schoolRef.id;

  const res = await createZoomRegistrant({
    room: roomId,
    student,
    classroom,
    school,
    goal,
    subjects,
    greeting,
    cameraStatus,
    cameraComment
  });
  if (!res.data.joinUrl) {
    throw new Error("unexpected error occured.");
  }

  return res.data.joinUrl;
}

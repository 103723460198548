import { convertToGroup, Group } from "@/entities/group";
import firebase from "firebase/app";

export async function getGroupOf(
  ref: firebase.firestore.DocumentReference
): Promise<Group | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();

  if (!snapshot.exists || !data) {
    return null;
  }

  return convertToGroup(data, snapshot.ref);
}

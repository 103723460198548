import {
  Submission,
  SubmissionStatus,
  convertToSubmissionData,
  submissionCollectionKey,
  ComplementedSubmission,
  ComplementedSubmissionData,
  getValuesFromFileMetadata
} from "@/entities/submission";
import {
  SubmissionMaster,
  convertToSubmissionMaster
} from "@/entities/submission_master";
import { getDocIdsOfStudentRef } from "@/entities/student";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import mime from "mime";

export async function getSubmissionOfStudent(
  studentRef: firebase.firestore.DocumentReference,
  options?: {
    submissionId?: string;
    limit?: number;
    offsetRef?: firebase.firestore.DocumentReference;
    submittableBefore?: number;
  }
): Promise<Submission[] | null> {
  try {
    const { submissionId, limit, offsetRef, submittableBefore } = {
      limit: 20,
      ...(options || {})
    };
    if (!submissionId) {
      // 提出物リストを取得
      let query = studentRef
        .collection(submissionCollectionKey)
        .orderBy("submittableFrom", "desc");
      if (limit > 0) {
        query = query.limit(limit);
      }
      if (submittableBefore && submittableBefore > 0) {
        query = query.where("submittableFrom", "<", submittableBefore);
      }
      if (offsetRef) {
        const offsetSnapshot = await offsetRef.get();
        query = query.startAfter(offsetSnapshot);
      }
      const submissionsSnapshot = await query.get();
      if (submissionsSnapshot.empty) {
        return [];
      }

      return submissionsSnapshot.docs
        .map(doc => ({
          data: convertToSubmissionData(doc.data()),
          ref: doc.ref
        }))
        .sort((a, b) => a.data.closedAt - b.data.closedAt);
    } else {
      // submissionId に対応するドキュメントを取得
      const snapshot = await studentRef
        .collection(submissionCollectionKey)
        .doc(submissionId)
        .get();
      const data = snapshot.data();
      if (!snapshot.exists || !data) {
        return null;
      }
      return [
        {
          ref: snapshot.ref,
          data: convertToSubmissionData(data)
        }
      ];
    }
  } catch (e) {
    return null;
  }
}

export async function putSubmission(
  submissionRef: firebase.firestore.DocumentReference,
  options: {
    imageUrls?: string[];
    fileUrls?: string[];
    status?: SubmissionStatus;
    submittedAt?: number;
    comment?: string;
  }
): Promise<void> {
  await submissionRef.update(options);
}

export async function createNotificationForTutors(
  studentRef: firebase.firestore.DocumentReference,
  data: {
    title: string;
    submissionDocId: string;
    content: string;
  }
): Promise<void> {
  const [schoolDocId, classroomDocId, studentDocId] =
    getDocIdsOfStudentRef(studentRef);
  const req = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("create_notification_for_tutors");
  const res = await req({
    schoolDocId,
    classroomDocId,
    studentDocId,
    submissionDocId: data.submissionDocId,
    title: data.title,
    content: data.content,
    type: "post_submission"
  });
  if (res.data.error) {
    throw new Error(res.data.error);
  }
  return;
}

export async function complementSubmissions(
  submissions: Submission[]
): Promise<ComplementedSubmission[]> {
  return new Promise((resolve, reject) => {
    // 同じ submissionMaster を重複して取得しなくてよいよう、重複を省いたリストを作る
    const submissionMastersToGet = submissions
      .map(submission => submission.data.submissionMasterRef)
      .filter((ref, index, self) => {
        return self.findIndex(m => m.id === ref.id) === index;
      });

    // 画像以外のファイルのメタデータを取得する
    const fileMetadataToGet = submissions.map(s => {
      return Promise.all(
        s.data.fileUrls.map(async url => {
          const ref = (await firebase.storage()).refFromURL(url);
          return ref.getMetadata();
        })
      );
    });

    Promise.all([
      // submissionMaster を取得する
      Promise.all(submissionMastersToGet.map(ref => ref.get())),
      // 画像以外のファイルのメタデータを取得する
      Promise.all(fileMetadataToGet),
      submissions
    ])
      .then(results => {
        const submissionMasters = results[0];
        const fileMetaData = results[1];
        const dataMaps = results[2].map((submission, submissionIndex) => {
          const matchedSubmissionMaster = submissionMasters.find(
            m => m.ref.id === submission.data.submissionMasterRef.id
          );
          const submissionMasterData = matchedSubmissionMaster?.data();
          let submissionMaster: SubmissionMaster | undefined;
          if (submissionMasterData && matchedSubmissionMaster) {
            submissionMaster = convertToSubmissionMaster(
              submissionMasterData,
              matchedSubmissionMaster.ref
            );
          }

          const ComplementedSubmissionData = {
            ...submission.data,
            fileDataList: fileMetaData[submissionIndex].map(
              (metadata, urlIndex) => {
                const values = getValuesFromFileMetadata(
                  metadata.size,
                  metadata.contentType
                );
                return {
                  url: submission.data.fileUrls[urlIndex],
                  ...values,
                  contentTypeToDisplay:
                    values.contentIconType !== "file"
                      ? values.contentTypeToDisplay
                      : `${mime.getExtension(metadata.contentType)}ファイル` ??
                        values.contentTypeToDisplay
                };
              }
            )
          };

          const result = {
            ...submission,
            ref: submission.ref,
            data: ComplementedSubmissionData as ComplementedSubmissionData,
            submissionMasterRef: submissionMaster?.ref,
            submissionMasterData: submissionMaster?.data
          } as ComplementedSubmission;
          return result;
        });
        resolve(dataMaps);
      })
      .catch(reject);
  });
}


import MNavItem from "@/components/MNavItem.vue";
import { Options, Vue } from "vue-class-component";

export type PageType = "home" | "message" | "setting" | "other";

@Options({
  components: {
    MNavItem
  }
})
export default class MBottomNav extends Vue {
  get current(): PageType {
    return this.$route.meta.pageType;
  }
}

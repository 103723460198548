import { Role, roleCollectionKey } from "@/entities/role";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";

function convertToRole(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Role {
  return {
    ref,
    data: {
      ref: data.ref,
      type: data.type,
      loginId: data.loginId,
      code: data.code
    }
  };
}

export async function signInWithIdAndPassword(
  loginId: string,
  password: string,
  token?: string
): Promise<firebase.User> {
  const studentLogin = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("student_login");

  const loginRes = await studentLogin({ loginId, password, token });
  if (loginRes.data.error) {
    throw new Error(loginRes.data.error);
  }

  const userCredential = await firebase
    .auth()
    .signInWithCustomToken(loginRes.data.token);

  if (!userCredential.user) {
    throw new Error("");
  }
  return userCredential.user;
}

export async function signInWithCode(
  code: string,
  token?: string
): Promise<firebase.User> {
  const studentLogin = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("student_login");

  const loginRes = await studentLogin({ code, token });
  if (loginRes.data.error) {
    throw new Error(loginRes.data.error);
  }

  const userCredential = await firebase
    .auth()
    .signInWithCustomToken(loginRes.data.token);

  if (!userCredential.user) {
    throw new Error("");
  }
  return userCredential.user;
}

export async function signOut() {
  await firebase.auth().signOut();
}

export async function getRole(id: string): Promise<Role | null> {
  const db = firebase.firestore();

  try {
    const snapshot = await db.collection(roleCollectionKey).doc(id).get();
    const data = snapshot.data();
    if (!snapshot.exists || !data) {
      return null;
    }

    return convertToRole(data, snapshot.ref);
  } catch (e) {
    console.error(e);
    return null;
  }
}

<template>
  <div class="opacity-75 fixed inset-0 z-40 bg-white" />
  <div class="fixed z-50 inset-0 flex flex-col justify-center items-center">
    <MLoadingIcon />
    <span class="container mx-auto text-gray-700 text-center mt-4">
      {{ loadText }}
    </span>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import store from "@/store";

@Options({
  components: {
    MLoadingIcon
  }
})
export default class MLoadingOverlay extends Vue {
  get loadText(): string {
    return store.state.loadText;
  }
}
</script>

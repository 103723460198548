<template>
  <div class="w-full flex justify-between bg-white shadow">
    <m-nav-item
      icon="home"
      text="ホーム"
      to="/"
      class="flex-1"
      :select="current == 'home'"
    />
    <m-nav-item
      icon="message"
      text="メッセージ"
      to="/message"
      class="flex-1"
      :select="current == 'message'"
    />
    <m-nav-item
      icon="setting"
      text="設定"
      to="/setting"
      class="flex-1"
      :select="current == 'setting'"
    />
  </div>
</template>

<script lang="ts">
import MNavItem from "@/components/MNavItem.vue";
import { Options, Vue } from "vue-class-component";

export type PageType = "home" | "message" | "setting" | "other";

@Options({
  components: {
    MNavItem
  }
})
export default class MBottomNav extends Vue {
  get current(): PageType {
    return this.$route.meta.pageType;
  }
}
</script>

import {
  Learning,
  LearningReflection,
  learningCollectionKey,
  convertToLearning,
  LearningSubject,
  LearningRecord,
  getDocIdsOfLearningRef
} from "@/entities/learning";
import { getDocIdsOfStudentRef } from "@/entities/student";
import firebase from "firebase/app";
import "firebase/firestore";
import dayjs from "dayjs";

export function timeStringToUnixtime(
  timeString: string,
  standardUnixtime?: number
): number {
  const [newHours, newMinutes] = timeString.split(":").map(_ => +_);
  const standard = standardUnixtime ? dayjs.unix(standardUnixtime) : dayjs();
  const result = standard
    .clone()
    .hour(newHours)
    .minute(newMinutes)
    .add(
      newHours * 60 + newMinutes < standard.hour() * 60 + standard.minute()
        ? 1
        : 0,
      "day"
    );
  return result.unix();
}

export function isValidTimerEnd(
  timeString: string,
  standardUnixtime: number = dayjs().unix()
): boolean {
  const asserted = timeStringToUnixtime(timeString, standardUnixtime);
  // 1 分以下または 20 時間以上先の時刻は受け付けない
  return (
    asserted - standardUnixtime < 20 * 60 * 60 &&
    asserted - standardUnixtime > 1 * 1 * 60
  );
}

export async function createLearningTimer(
  studentRef: firebase.firestore.DocumentReference,
  goal: string,
  subjects: LearningSubject[],
  timerStart: number,
  timerEnd: number
): Promise<{ learningDocId: string }> {
  const [schoolDocId, classroomDocId, studentDocId] =
    getDocIdsOfStudentRef(studentRef);
  const req = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("create_learning_timer");
  const res = await req({
    schoolDocId,
    classroomDocId,
    studentDocId,
    goal,
    subjects,
    timerStart,
    timerEnd
  });
  if (res.data.error) {
    throw new Error(res.data.error);
  }
  return { learningDocId: res.data.learningDocId };
}

export async function extendLearningTimer(
  studentRef: firebase.firestore.DocumentReference,
  learningDocId: string,
  timerEnd: string
): Promise<void> {
  const [schoolDocId, classroomDocId, studentDocId] =
    getDocIdsOfStudentRef(studentRef);
  const req = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("extend_learning_timer");
  const res = await req({
    schoolDocId,
    classroomDocId,
    studentDocId,
    learningDocId,
    timerEnd
  });
  if (res.data.error) {
    throw new Error(res.data.error);
  }
  return;
}

export async function getLearning(
  ref: firebase.firestore.DocumentReference
): Promise<Learning | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }

  return convertToLearning(data, snapshot.ref);
}

export async function getLearningsOf(
  studentRef: firebase.firestore.DocumentReference,
  limit = 0,
  offsetRef?: firebase.firestore.DocumentReference
): Promise<Learning[] | null> {
  let query = studentRef
    .collection(learningCollectionKey)
    .orderBy("timestamp", "desc");
  if (limit > 0) {
    query = query.limit(limit);
  }
  if (offsetRef) {
    const offsetSnapshot = await offsetRef.get();
    query = query.startAfter(offsetSnapshot);
  }

  try {
    const learningsSnapshot = await query.get();
    if (learningsSnapshot.empty) {
      return [];
    }

    return learningsSnapshot.docs
      .map(doc => convertToLearning(doc.data(), doc.ref))
      .filter(learning => learning.data.totalTime > 0);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function setReflectionToLearning(
  ref: firebase.firestore.DocumentReference,
  reflection: LearningReflection,
  subjects: LearningSubject[]
): Promise<void> {
  await ref.update({ reflection, subjects });
}

export async function updateLearningTimer(
  learninRef: firebase.firestore.DocumentReference,
  start: number,
  tracks: LearningRecord[]
): Promise<void> {
  await learninRef.update({
    "meta.timer.start": start,
    "meta.timer.tracks": tracks
  });
}

export async function updateTimerLastDisplayed(
  learninRef: firebase.firestore.DocumentReference
): Promise<void> {
  const now = Math.floor(Date.now() / 1000);
  const learning = await getLearning(learninRef);
  if (!learning || !learning.data.meta || !learning.data.meta.timer) {
    return;
  }
  await learninRef.update({ "meta.timer.lastDisplayed": now });
}

export async function stopLearningTimer(
  learning: Learning,
  recordFully: boolean
): Promise<void> {
  const timer = learning.data.meta && learning.data.meta.timer;
  if (!timer) {
    return;
  }
  const [schoolDocId, classroomDocId, studentDocId] = getDocIdsOfLearningRef(
    learning.ref
  );
  const stopLearningTimerRequest = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("stop_learning_timer");

  const res = await stopLearningTimerRequest({
    schoolDocId,
    classroomDocId,
    studentDocId,
    learningDocId: learning.ref.id,
    recordFully
  });
  if (res.data.error) {
    throw new Error(res.data.error);
  }
}

<template>
  <div v-if="showBottomNav" class="h-screen flex flex-col justify-end">
    <div class="flex-1 overflow-y-scroll overflow-x-hidden">
      <slot />
    </div>
    <m-bottom-nav v-if="!isInMingakuStudentApp" class="flex-none" />
  </div>
  <slot v-else />
</template>

<script lang="ts">
import MBottomNav from "@/components/MBottomNav.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MBottomNav
  }
})
export default class MPageTemplate extends Vue {
  get isInLiff(): boolean {
    return store.state.isInLiff;
  }

  get isInMingakuStudentApp(): boolean {
    return store.state.isInMingakuStudentApp;
  }

  get showBottomNav(): boolean {
    if (this.$route.meta.pageType === "other") {
      return false;
    }
    return !this.isInLiff || !this.isInMingakuStudentApp;
  }
}
</script>

<style>
.todo-reflection-best {
  filter: invert(21%) sepia(100%) saturate(2153%) hue-rotate(287deg)
    brightness(113%) contrast(143%);
}
.todo-reflection-good {
  filter: invert(49%) sepia(61%) saturate(1801%) hue-rotate(197deg)
    brightness(102%) contrast(101%);
}
.todo-reflection-bad {
  filter: invert(29%) sepia(4%) saturate(12%) hue-rotate(343deg) brightness(97%)
    contrast(86%);
}
</style>

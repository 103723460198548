
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";
import store from "@/store";

@Options({
  components: {
    MIcon
  },
  props: {
    icon: String,
    text: String,
    to: String,
    select: Boolean
  }
})
export default class MNavItem extends Vue {
  icon!: string;
  text!: string;
  to!: string;
  select = false;
  messages = [];

  get showUnreadCount(): boolean {
    return this.icon === "message";
  }

  get unreadMessagesLength(): number {
    return store.state.unreadMessagesLength;
  }

  get dynamicWrapClass(): string {
    if (this.select) {
      return "text-primary-500";
    } else {
      return "text-gray-500";
    }
  }
}
